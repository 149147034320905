import {createRoutes} from '@/utils/core';
import BasicLayout from '@/layouts/BasicLayout';
// import CardLayout from '@/layouts/CardLayout';
import UserLayout from '@/layouts/UserLayout';
import NotFound from './Pages/404';
import Login from './Login';
import Dashboard from './Dashboard';
import Blank from './Blank';
import Admin from './Setting/Admin';
import Atommodule from './Setting/Atom-module';
import Appmodule from './Setting/App-module';
import Role from './Setting/Role';
import WebuserList from './User/Webuser';
import SubsidyList from './Finance/Subsidy';
import AdminInfo from './Admin/Info';
import UserList from './User/List';
import TrailerList from './Trailer';
import Banner from './Car/Banner';
import SocialCar from './Car/SocialCar';
import socialCarDetail from './Car/SocialCarDetail';
import AddCar from './Car/AddCar';
import SeizedCar from './Car/SeizedCar';
import SeizedCarDetail from './Car/SeizedCarDetail';
import SeizedVehicle from './Car/SeizedVehicle';
import Channel from './Operation/Channel';
import Invite from './Operation/Invite';
import Organization from './Setting/Organization';
import WareHouse from './Warehouse';
import DisassembleGroup from './Group';
import PrintForm from './Car/PrintForm';
import Result from './Car/Result';
import DisassembleList from './Disassemble/dsmbList';
import SeizedCarPrint from './Car/SeizedCarPrint';

const routesConfig = (app) => ([
  {
    path: '/sign',
    title: '登录',
    indexRoute: '/sign/login',
    component: UserLayout,
    childRoutes: [
      Login(app),
      NotFound()  // 这个要放到最下面，当所有路由当没匹配到时会进入这个页面
    ]
  }, {
    path: '/',
    title: '首页',
    component: BasicLayout,
    indexRoute: '/dashboard',
    childRoutes: [
      Dashboard(app),
      Blank(app),
      Admin(app),
      Atommodule(app),
      Appmodule(app),
      Banner(app),
      SocialCar(app),
      socialCarDetail(app),
      AddCar(app),
      SeizedCar(app),
      SeizedCarDetail(app),
      SeizedVehicle(app),
      UserList(app),
      WebuserList(app),
      SubsidyList(app),
      TrailerList(app),
      Role(app),
      AdminInfo(app),
      Channel(app),
      Invite(app),
      Organization(app),
      WareHouse(app),
      DisassembleGroup(app),
      PrintForm(app),
      DisassembleList(app),
      SeizedCarPrint(app),
      Result(app),
      NotFound(), // 这个要放到最下面，当所有路由当没匹配到时会进入这个页面
    ]
  }
]);

export default app => createRoutes(app, routesConfig);